<template>
  <div class="foot">
    <img src="../assets/img/foot_1.png" alt="" />
    <div class="footLeft">
      <a
              target="_blank"
              class="playBtn"
              href="https://play.google.com/store/apps/details?id=credit.prestamos.personale.cash.efectivo.rapido"
      ><img src="@/assets/img/foot_2.png" alt="" />
      </a>
    </div>
    <div class="footRight">
      <div class="top">
        <img src="../assets/image/youxiang.png" /> Queja de cobranza:
      </div>
      <div>credifast.cobranza.mx@gmail.com</div>
    </div>
    <!-- href="https://play.google.com/store/apps/details?id=credit.prestamos.personale.cash.efectivo" -->
  </div>
</template>

<script>
  import "./scss/foot.scss";

  export default {
    name: "footPage",
    data() {
      return {};
    },
  };
</script>
