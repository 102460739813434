<template>
  <div class="Aviso">
    <headNav :activeNav="'Aviso'"></headNav>
    <div class="banner"></div>
    <div class="bigContent" style="margin-top: 210px !important">
      <p class="title">Aviso de privacidad</p>
      <div class="content">
        <p>YUNDS SUERTE, S.A. DE C.V.</p>
        <p>COATL  Street, Block 32, lot 11, colonia  PEDREGAL DE SANTO DOMINGO, Coyoacán, Mexico City, Code Postal 04369</p>
        <p>
          En cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en adelante la “Ley”), su Reglamento y los Lineamientos del Aviso de Privacidad, estos últimos publicados en el Diario Oficial de la Federación de fecha 17 de enero de 2013, YUNDS SUERTE, S.A. DE C.V. pone a su disposición el presente Aviso de Privacidad y hace de su conocimiento los términos y condiciones siguientes:
        </p>
        <p style="font-weight: bold;">I. Del Tratamiento de los Datos Personales.</p>
        <p>
          YUNDS SUERTE, S.A. DE C.V., con domicilio en Calle COATL, Cuadra 32, lote 11, colonia PEDREGAL DE SANTO DOMINGO, Coyoacán, Ciudad de México, Código Postal 04369, es responsable de la obtención, uso, divulgación, o almacenamiento, acceso, manejo, aprovechamiento, transferencia o disposición de datos personales (Adelante “Tratamiento”) que proporcione la persona que acceda a la página web a YUNDS SUERTE (el Usuario o Titular) .
        </p>

        <p style="font-weight: bold;">II. Datos Personales sujetos a Tratamiento.</p>
        <p>
          YUNDS SUERTE recabará Datos Personales necesarios para cumplir con su objeto social, políticas en materia de prevención y detección de operaciones con recursos de procedencia ilícita y financiamiento al terrorismo y demás políticas corporativas, ya sea personal, directamente a través de sus ejecutivos, funcionarios, empleados, promotores, o indirectamente por medio de sus asesores o comisionistas, pudiendo utilizar mecanismos en medios remotos o locales de comunicación electrónica, óptica u otra tecnología, como puede ser a través de nuestro portal web, aplicación, correo electrónico o postal, servicios en línea, vía telefónica, que le permitan recabar datos personales de manera automática y simultánea, incluyendo las referencias personales y comerciales que llegue a proporcionar el titular de la información.
        </p>
        <p style="font-weight: bold;">¿QUÉ DATOS PERSONALES USAMOS</p>

        <p>
          YUNDS SUERTE recabará de ti (en adelante el “Titular” o “tú”) los siguientes datos personales (en lo sucesivo los “datos” o los “datos personales”) en tu calidad de uno de nuestros clientes:
        </p>
        <ul>
          <li>
            datos de contacto (número de teléfono celular, correo electrónico, domicilio personal);
          </li>
          <li>
            datos de identificación (nombre, fecha de nacimiento, captura de imagen, identificación oficial, género asignado);
          </li>
          <li>
            datos biométricos;
          </li>
          <li>
            datos de localización y geolocalización (ubicación aproximada, ubicación precisa, huso horario);
          </li>
          <li>
            datos laborales y educativos;
          </li>
          <li>
            datos de comunicación con YUNDS SUERTE (solicitudes de atención al cliente, respuestas a aclaraciones u otras comunicaciones a través de mensajes SMS, correo electrónico o grabación de llamadas);
          </li>
          <li>
            datos de tu teléfono celular o dispositivo móvil (información del hardware, IMEI, dirección IP, dirección MAC, GPS ADID o identificación de otro dispositivo, operador móvil);
          </li>
          <li>
            datos de uso de tu dispositivo (lista de aplicaciones instaladas, datos de cuentas);
          </li>
          <li>
            datos de SMS (la marca de tiempo, el número de teléfono y el contenido del SMS);
          </li>
          <li>
            lista de contactos, mensajes de texto que envías, registro y frecuencia de llamada de tu teléfono móvil;
          </li>
          <li>
            datos patrimoniales y financieros (ingresos o datos o documentos de tu empleo, grado de escolaridad, propósito o uso que se le dará al préstamo);
          </li>
          <li>
            datos que proporciones en tu solicitud de préstamo;
          </li>
          <li>
            datos transaccionales de las operaciones que realizas con nosotros o a través de nosotros, así como de las operaciones que realizas con transmisores de dinero, instituciones de fondos de pago electrónico, instituciones de financiamiento colectivo, instituciones de crédito y otras instituciones financieras o comercios electrónicos; y
          </li>
          <li>
            datos de uso de la aplicación de YUNDS SUERTE y de otras aplicaciones (detalles de instalación, tráfico, navegación).
          </li>
        </ul>
        <p style="font-weight: bold;">¿USAMOS DATOS PERSONALES SENSIBLES?</p>
        <p>
          YUNDS SUERTE no solicita directamente datos raciales o de origen étnico, de salud, creencias filosóficas o religiosas, afiliación sindical, opiniones políticas, preferencias sexuales (salvo por tu género asignado que se requiere para temas de prevención de lavado de dinero). Sin embargo, recabamos información relacionadav con tu lista de contactos, mensajes de texto, registro de mensajes de texto e imágenes que incidentalmente puede tener datos personales sensibles.
        </p>
        <p style="font-weight: bold;">NECESIDAD DE LA RECOLECCIÓN Y PROCESAMIENTO DE TUS DATOS</p>
        <p>
          YUNDS SUERTE te informa que los datos personales y personales sensibles (recolectados incidentalmente) que te solicita, de conformidad con el presente Aviso de Privacidad, son necesarios y suficientes para que YUNDS SUERTE realice un proceso de evaluación de riesgo de crédito y probabilidad de pago (scoring crediticio) en base a tu perfil y así poder determinar tu capacidad crediticia, lo cual es la base fundamental de la determinación de YUNDS SUERTE para aprobar o rechazar el otorgamiento de tu solicitud de crédito y dar inicio a la relación jurídica contigo, así como para las demás finalidades primarias y secundarias que se señalan en el presente Aviso de Privacidad. Cabe resaltar que, la decisión final al respecto no es tomada de forma manual por un oficial o empleado de YUNDS SUERTE, sino que, dicha decisión es tomada por los sistemas automatizados deYUNDS SUERTE de conformidad con lo señalado en la sección “Decisiones Automatizadas” del presente Aviso de Privacidad.
        </p>
        <p style="font-weight: bold;">¿CÓMO OBTENEMOS Y ACTUALIZAMOS TUS DATOS?</p>
        <p>
          Los datos personales serán obtenidos y actualizados a través de:
        </p>
        <ul class="number_ul">
          <li>Ti, cuando bajas la aplicación de YUNDS SUERTE y/o nos solicitas un préstamo;</li>
          <li>
            Tus otras interacciones con nosotros, incluyendo datos personales que voluntariamente compartes con nuestro departamento de atención al cliente o con nuestros empleados y agentes;
          </li>
          <li>
            Tu teléfono celular o dispositivo móvil cuando otorgas acceso y permisos que se describe abajo;
          </li>
          <li>
            Buros de crédito o agencias para prevenir el fraude;
          </li>
          <li>
            Cookies, web beacons y otros medios remotos de recolección automática de datos;
          </li>
          <li>
            Transmisores de dinero instituciones de fondos de pago electrónico, instituciones de financiamiento colectivo, instituciones de crédito y otras instituciones financieras y comercios electrónicos, con tu consentimiento, cuando sea necesario; y/o
          </li>
          <li>
            Terceros y otras fuentes públicas, con tu consentimiento, cuando sea necesario.
          </li>
        </ul>

        <p style="font-weight: bold;">¿PARA QUÉ USAMOS TUS DATOS?</p>
        <p>Las finalidades primarias (en lo sucesivo, las “Finalidades Primarias”) que dan origen y son necesarias para el mantenimiento y cumplimiento de la relación jurídica entre YUNDS SUERTE y tú son las siguientes:</p>
        <ul>
          <li>
            Verificar tu identidad, prevenir fraudes y cumplir con nuestras obligaciones de “conoce a tu cliente” bajo las leyes aplicables de prevención de lavado de dinero;
          </li>
          <li>
            Crear tu perfil crediticio y asignarte una calificación crediticia para determinar si eres elegible para usar los productos y servicios de YUNDS SUERTE;
          </li>
          <li>
            Llevar a cabo la identificación y el conocimiento mediante recolección de datos personales, así como la integración de tu expediente que se mantendrá en la infraestructura tecnológica y sistemas de información de YUNDS SUERTE y en otros archivos;
          </li>
          <li>
            Incorporar los datos personales en instrumentos jurídicos, dar cumplimiento a los contratos que celebres con YUNDS SUERTE, incluyendo, pero no limitado a ciertos contratos de usuario de nuestra aplicación y plataformas y los contratos de préstamo o crédito entre el Titular y YUNDS SUERTE y administrar y manejar dichos contratos;
          </li>
          <li>
            Originar, gestionar, dar servicio, mantener y exigir las deudas del Titular;
          </li>
          <li>
            Brindarte servicios y gestionar tu cuenta con YUNDS SUERTE y darte soporte como cliente;
          </li>
          <li>
            Enviarte información de transacciones o notificaciones de transacciones, recordatorios de pago y anuncios a través de servicios de telefonía automáticos o mensajes pre-grabados, mensajes de texto o email;
          </li>
          <li>
            Ceder o transmitir a un tercero, mediante cualquier medio, los derechos y/u obligaciones derivadas de los contratos antes señalados;
          </li>
          <li>
            Utilizar los datos personales, en cualquier tipo de acto o diligencia de cobranza judicial o extrajudicial;
          </li>
          <li>
            Cumplir con todas las leyes, reglamentos y disposiciones de carácter general aplicables;
          </li>
          <li>
            Investigar cambios en tu perfil transaccional;
          </li>
          <li>
            Prevención de fraudes;
          </li>
          <li>
            Obtener datos personales para mejorar los modelos crediticios de YUNDS SUERTE, sus modelos de datos y otros servicios; y
          </li>
          <li>
            Analizar el comportamiento del cliente y mantener la calidad de nuestros servicios.
          </li>
        </ul>
        <p>
          Las finalidades secundarias (en lo sucesivo, las “Finalidades Secundarias”) que no son necesarias para el mantenimiento y cumplimiento de la relación jurídica entre YUNDS SUERTE y tú, son las siguientes:
        </p>
        <ul>
          <li>
            Envío de noticias, invitaciones a eventos de YUNDS SUERTE sus empresas subsidiarias  y afiliadas;
          </li>
          <li>
            Realización de encuestas para mejorar nuestro servicio;
          </li>
          <li>
            Uso de imágenes y testimonios que nos proporciones voluntariamente para fines publicitarios; y
          </li>
          <li>
            La mercadotecnia, publicidad y/o promoción de los productos y/o servicios que sean ofrecidos por YUNDS SUERTE al Titular, por cualquier medio material y/o electrónico. Con tu consentimiento al presente Aviso de Privacidad otorgas tu consentimiento expreso para que podamos contactarte con la finalidad de ofrecerte servicios financieros.
          </li>
        </ul>
        <p>
          Tu consentimiento es necesario para las finalidades secundarias. Si no estás de acuerdo con el uso de tus datos de contacto para dichas finalidades secundarias, envíanos un correo a credifast.mex.atc@gmail.com. Esperaremos cinco días hábiles antes de usar tus datos personales para finalidades secundarias.
        </p>

        <p style="font-weight: bold;">¿A QUÉ PERMISOS EN TU DISPOSITIVO ACCEDE YUNDS SUERTE?</p>
        <p>
          Dependiendo de tu sistema operativo Android y de la versión de la aplicación instalada en tu dispositivo, la aplicación de YUNDS SUERTE puede acceder a los siguientes permisos del dispositivo. Mantén siempre actualizada tu aplicación de YUNDS SUERTE para que puedas utilizar las funcionalidades más recientes y seguras.
        </p>
        <ul>
          <li>
            Contactos – Utilizamos permiso de lista de contactos para detectar referencias y ayudar los usuarios llenar automáticamente su solictud de préstamo, garantizando la precisión de las informaciones. La aplicación recopila información de contacto incluido nombre, número de teléfono, tipo de cuenta, último contacto modificado, favoritos y otros datos opcionales (como relación con contactos y dirección residencial) para optimizar la verificación de riesgo y situación financiera, también nos ayudarán a evaluar concretamente su elegibilidad de solicitud de préstamo. Nuestra aplicación cargará información de contactos a nuestro servicio https://data.Credifast.work . El usuario puede deshabilitar este permiso en cualquier momento en la configuración de la aplicación en su dispositivo. (Deshabilitar permiso puede afectar los servicios que le brindamos)
          </li>
          <li>
            SMS – YUNDS SUERTE cargará y transferirá sus datos, incluidos contactos, números de teléfono, mensajes SMS, desde su teléfono móvil a nuestro servidor https://data.Credifast.work. Las informaciones cargadas y transmitidas se utilizarán para la gestión de riesgos y controles antifraude. YUNDS SUERTE nunca compartirá datos con terceros sin su permiso. Solo recopilaremos su información con su consentimiento. Estos mensajes SMS ayudan a YUNDS SUERTE a determinar si usted es elegible para nuestros servicios y optimizar nuestro modelo de crédito. Sus datos se mantendrán en la plataforma durante 1 año a partir de la fecha de carga. Si desea eliminar sus datos privados, puede enviar una solicitud al servicio de atención al cliente a este correo electrónico: credifast.mex.atc@gmail.com, y podemos eliminarlos manualmente dentro de (3-5 días hábiles).
          </li>
          <li>
            Cámara – YUNDS SUERTE te pedirá que subas fotos de tu identificación. YUNDS SUERTE también puede pedirte que subas copias de documentos para acreditar tus ingresos.
          </li>
          <li>
            Leer, modificar o borrar el contenido de tu tarjeta SD – YUNDS SUERTE también puede solicitarte que subas fotos de documentos para acreditar tus ingresos.
          </li>
          <li>
            Leer el estado del teléfono y la identidad – Cuando te registras para obtener una cuenta de YUNDS SUERTE, YUNDS SUERTE recupera tu número de teléfono de tu dispositivo automáticamente. Esto asegura que el número de teléfono celular está activo y es correcto, y que está vinculado al dispositivo que estás usando para abrir la cuenta.
          </li>
          <li>
            Ejecutar al inicio – Esto permite que la aplicación de YUNDS SUERTE envíe notificaciones a tu dispositivo cuando lo reinicies.
          </li>
          <li>
            Ver y cambiar la conectividad de la red – Esto se utiliza para notificar a la aplicación de YUNDS SUERTE cuando la conectividad de la red cambie para que podamos determinar si estás conectado a Internet o no.
          </li>
          <li>
            Ver conexiones Wi-Fi – YUNDS SUERTE utiliza la dirección IP y el tipo de red de tu dispositivo para detectar y prevenir fraudes.
          </li>
          <li>
            Recibir datos de internet – YUNDS SUERTE necesita este permiso para enviar solicitudes a través de la aplicación de YUNDS SUERTE y para permitir que dicha aplicación acceda a internet.
          </li>
          <li>
            Evitar que el teléfono entre en modo inactivo – Este permiso es requerido por algunas de las características y servicios dentro de la aplicación de YUNDS SUERTE , como los mensajes enviados por la propia aplicación.
          </li>
          <li>
            Datos de Identificación - nombre completo, dirección, teléfonos de contacto, estado civil, género, país, lugar y fecha de nacimiento, edad, identificación oficial vigente, registro federal de contribuyentes (RFC), clave única de registro de población (CURP), Firma electrónica avanzada (FIEL), correo electrónico, datos académicos y sobre características físicas como son fotografías del poseedor de los datos o su domicilio, entre otros.
          </li>
          <li>
            Datos Laborales: actividad principal, tipo de empleo, fuentes de ingresos, ingresos mensuales, dirección del centro de trabajo, teléfono del centro de trabajo, fecha de ingreso al servicio, cargo dentro de la empresa/institución, nombre del jefe directo o representante del centro de trabajo, y actividades productivas adicionales, entre otros.
          </li>
          <li>
            Datos Comerciales - fuente de ingresos, actividades empresariales, experiencia profesional o empresarial y otros relacionados.
          </li>
          <li>
            Datos de Cónyuge o Concubina  - nombre completo, dirección, ocupación, teléfonos de contacto, estado civil, género, país, lugar y fecha de nacimiento, edad.
          </li>
          <li>
            Datos Bancarios o patrimoniales  - nombre de la institución bancaria donde recibe su nómina o en la cual recibe sus ingresos, número de cuenta y/o tarjeta y/o contrato, Clave Bancaria Estandarizada (CLABE), tipo de cuenta, nombre y número de la sucursal y/o plaza, entre otros, como pueden ser la huella, foto y firma digital.
          </li>
          <li>
            Datos de Localización - Verificamos su ubicación en tiempo real cuando se utiliza la aplicación.
          </li>
          <li>
            Calendario de su teléfono móvil: Se usa para recordarle la fecha de pago para evitar el impacto del pago vencido en su crédito.
          </li>
          <li>
            Información básica del teléfono móvil  -Obtener la información como tipo de celular para brindar mejor servicio, respecto a actualizaciones y compatibilidad de nuestra app con su dispositivo.
          </li>
        </ul>

        <p style="font-weight: bold;">¿CON QUIÉN COMPARTIMOS SUS DATOS?</p>
        <p>
          Los datos personales se transferirán a nivel nacional e internacional, a las personas o entidades ubicadas en los siguientes tipos, categorías o sectores, con las siguientes finalidades:
        </p>
        <p>
          <span style="font-weight: bold;">A.</span>
          La aplicación YUNDS SUERTE,a otras sociedades controladoras, subsidiarias o afiliadas, o a una sociedad matriz o a cualquier sociedad del mismo grupo de YUNDS SUERTE, que operen bajo los mismos procesos y políticas internas, con la finalidad de hacer uso de servicios compartidos dentro del grupo de YUNDS SUERTE y además para:
        </p>
        <ul class="number_ul">
          <li>
            i. Llevar a cabo la identificación y el conocimiento mediante recolección de datos personales del Titular, así como la integración de los datos personales en la infraestructura tecnológica de YUNDS SUERTE, así como en sus sistemas de información y otros archivos;
          </li>
          <li>
            i.i Incorporar los datos personales del Titular en instrumentos jurídicos, dar cumplimiento a las obligaciones del Titular bajo los contratos celebrados entre el Titular y YUNDS SUERTE, incluyendo de manera enunciativa, más no limitativa, ciertos contratos de usuario de nuestra aplicación y plataformas y los contratos de préstamo o crédito entre el Titular y YUNDS SUERTE y administrar y manejar dichos contratos;
          </li>
          <li>
            iii. Ceder o transmitir a un tercero, mediante cualquier medio, los derechos y/u obligaciones derivadas de los contratos antes señalados;
          </li>
          <li>
            iv. Utilizar los datos personales, en cualquier tipo de acto o diligencia de cobranza judicial o extrajudicial;
            Cumplir con todas las leyes, reglamentos y disposiciones de carácter general aplicables;
          </li>
          <li>
            vi. Prospectar y evaluar los datos personales para crear y asignar al titular una calificación crediticia o grado de calificación similar.
          </li>
          <li>
            vii. Obtener información para mejorar nuestro modelo crediticio, de datos y de servicios;
          </li>
          <li>
            viii. Investigar cambios en tu perfil transaccional;
          </li>
          <li>
            ix. Prevención de fraudes;
          </li>
          <li>
            x. Originar, gestionar, dar servicio, mantener y exigir la deuda del Titular.
          </li>
        </ul>
        <p>
          <span style="font-weight: bold;">B. </span>
          A autoridades nacionales y extranjeras competentes, con la finalidad de dar cumplimiento a alguna ley, reglamento o disposición legal aplicable, así como con la finalidad de prevenir un daño.
        </p>
        <p>
          <span style="font-weight: bold;">C. </span>
          A terceros que adquieran la cartera crediticia de YUNDS SUERTE o que otorguen financiamiento a YUNDS SUERTE, con la finalidad de que YUNDS SUERTE  pueda obtener financiamiento o recursos.
        </p>
        <p>
          <span style="font-weight: bold;">D. </span>
          A terceros interesados en otorgar financiamientos o adquirir las acciones o activos de YUNDS SUERTE  o de sus accionistas o filiales en Méxicoo alrededor del mundo o en fusionarse con YUNDS SUERTE.
        </p>
        <p>
          <span style="font-weight: bold;">E. </span>
          Con nuestros socios comerciales, para fines de prospección comercial y servicios relacionados ofrecidos por YUNDS SUERTE y para darte acceso a los servicios de terceros dentro de la aplicación de YUNDS SUERTE.
        </p>
        <p>
          Asimismo, y de acuerdo con las leyes vigentes, YUNDS SUERTE deberá, con el objeto de cumplir con las Finalidades Primarias descritas en el presente, transferir de tiempo en tiempo, información de crédito del Titular a las Sociedades de Información Crediticia o Burós de Crédito, y en este sentido, al aceptar el presente Aviso de Privacidad (en lo sucesivo, este “Aviso”) y al consentir a la celebración del Contrato de Usuario, usted confirma que podemos transmitir su información a dichas Sociedades de Información Crediticia o Burós de Crédito, incluyendo información sobre el incumplimiento a los contratos celebrados entre el Titular y YUNDS SUERTE, entre otros el Contrato del Usuario.
        </p>
        <p>
          También compartimos tus datos personales con terceros prestadores de servicios relacionados con los desembolsos, la transferencia de fondos, servicios de pagos, proveedores de seguridad de información, aseguradoras, despachos de cobranza, asesores contables y legales, proveedores de software para análisis de datos, transferencias y servicios de almacenamiento y otros prestadores de servicios que procesan tus datos personales por cuenta de YUNDS SUERTE.
        </p>
        <p style="font-weight: bold;">CLÁUSULA PARA CONSENTIR LA TRANSFERENCIA</p>
        <p>Por favor considerar que no será necesario tener tu consentimiento para llevar a cabo: (i) la transferencia indicada en los incisos A y B de la sección anterior, por encontrarse en el supuesto establecido en la fracción IV del artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y (ii) aquellas transferencias que se encuentren dentro de los supuestos establecidos en el Artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares. Tampoco requerimos tu consentimiento para compartir tus Datos Personales con encargados como los prestadores de servicios mencionados anteriormente.</p>
        <p>Para llevar a cabo las transferencias en los incisos C, D y E de la sección anterior, sí requerimos su consentimiento. Usted otorga su consentimiento para estas transferencias al aceptar este Aviso de Privacidad.</p>

        <p style="font-weight: bold;">TUS DERECHOS ARCO</p>
        <p>Tú, por ti mismo o mediante tu representante legal debidamente acreditado, puedes ejercer tus derechos de acceso, rectificación, cancelación u oposición al tratamiento de tus datos personales (los “Derechos ARCO”). Para ello podrás solicitar un formato de ejercicio de Derechos ARCO mediante el envío de un correo electrónico a la dirección credifast.mex.atc@gmail.com, en atención de Departamento de Protección de Datos Personales/Equipo de Atención al Cliente, al número telefónico +52 55 5941 8349 o bien a la dirección de YUNDS SUERTE indicada en este Aviso con el nombre y los datos de contacto del Titular.</p>
        <p>El formato de ejercicio de Derechos ARCO se deberá llenar, firmar y presentarse acompañado de la siguiente documentación, a fin de que pueda llevarse a cabo la autenticación de usted:</p>

        <ul class="number_ul">
          <li>
            Tu identificación oficial vigente (Credencial del Instituto Nacional Electoral, Pasaporte, Cartilla del servicio Militar Nacional o Cédula Profesional).
          </li>
          <li>
            En los casos en que el ejercicio de los Derechos ARCO se realice a través de tu representante legal, deberá acompañarse la identificación oficial del representante, así como el poder correspondiente protocolizado que acredite la representación legal conferida por el Titular.
          </li>
          <li>
            Cuando se quiera ejercer el derecho de rectificación, deberá exhibirse la documentación que acredite el cambio solicitado de acuerdo a los datos personales a rectificar.
          </li>
        </ul>
        <p>
          YUNDS SUERTE responderá a dicho formato después de 20 días hábiles contados a partir de la fecha en que fue recibido el mismo. Este plazo podrá ser ampliado hasta por 20 días hábiles más, cuando el caso lo amerite, previa notificación a usted. YUNDS SUERTE le comunicará la resolución adoptada a través de las opciones elegidas por éste en el formato de ejercicio de Derechos ARCO.
        </p>


        <p style="font-weight: bold;">PUEDES NEGARTE O REVOCAR EL CONSENTIMIENTO PARA TRATAR TUS DATOS</p>
        <p>
          En caso de que desees revocar tu consentimiento o negarte al tratamiento de tus datos personales para las Finalidades Secundarias, con posterioridad a la firma y aceptación del presente Aviso, deberás enviar un correo electrónico a la dirección indicada en la sección anterior al Departamento de Protección de Datos Personales/Equipo de Atención al Cliente, credifast.mex.atc@gmail.com  siguiendo el procedimiento para la Negativa al Tratamiento y Revocación del Consentimiento que se describe en este Aviso.
        </p>
        <p>
          De acuerdo con lo anterior, el Departamento de Protección de Datos Personales/Equipo de Atención al Cliente es el encargado de atender todas las solicitudes de ejercicio de los derechos ARCO (acceso, rectificación, cancelación u oposición) mismo que puedes contactar en caso de cualquier duda o solicitud con respecto a tus datos personales a través del correo electrónico y teléfono antes indicados.
        </p>
        <p style="font-weight: bold;">¿QUÉ MEDIOS TIENES PARA LIMITAR EL USO Y DIVULGACIÓN DE DATOS PERSONALES?</p>
        <p>
          YUNDS SUERTE  ha creado un listado de exclusión para personas que no deseen recibir publicidad de YUNDS SUERTE . Si recibes publicidad de YUNDS SUERTE  y no deseas seguir recibiéndola, podrás enviar un correo electrónico con tu nombre completo a credifast.mex.atc@gmail.com, solicitando tu eliminación de la lista de distribución con base en la cual se lleva a cabo el envío de la información o publicidad. En el mismo correo electrónico podrás solicitar más datos acerca de este listado.
        </p>
        <p style="font-weight: bold;">CAMBIOS AL AVISO DE PRIVACIDAD</p>
        <p>
          El presente Aviso se encuentra publicado para su consulta, en la página de Internet de YUNDS SUERTE (https://www.Credifast.work/). De igual forma, las modificaciones que en cualquier momento se hagan al presente Aviso, serán publicadas en dicha dirección.
        </p>
        <p>
          Al hacer clic en el botón “ACEPTAR” al calce, el Titular otorga su consentimiento expreso en términos del presente Aviso y por ende, consiente que sus Datos Personales sean tratados por YUNDS SUERTE conforme a lo previsto en el presente Aviso, destinados para las finalidades indicadas en el presente y transferidos a los terceros establecidos aquí indicados.
        </p>
        <p style="font-weight: bold;">DECISIONES AUTOMATIZADAS</p>
        <p>
          Tomamos algunas decisiones en relación con nuestros productos y servicios en forma automatizada sin intervención de una persona física en la valoración.
        </p>
        <p style="font-weight: bold;">I. Decisiones de otorgar préstamos</p>
        <p>
          Cuando solicitas un préstamo, usaremos un proceso automatizado para decidir si prestamos dinero y/o para toma de decisiones respecto a tu elegibilidad para nuestros productos o servicios.
        </p>
        <p>
          Nuestros procesos de revisión y otorgamiento de crédito utilizan inteligencia artificial para procesar tus datos personales y valorar tu capacidad crediticia. El tratamiento de tus datos personales es automatizado sin intervención o valoración humana. El uso de dichos procesos automatizados para verificar tu capacidad crediticia significa que podemos automáticamente decidir que no eres elegible para un préstamo o para un plazo o condiciones particulares. Nuestros modelos de suscripción y otorgamiento de crédito son probados regularmente para asegurarnos que son justos, exactos y sin prejuicios.
        </p>
        <p style="font-weight: bold;">II. Detección de fraudes</p>
        <p>
          YUNDS SUERTE  también utiliza procesos automatizados para detectar, combatir y prevenir fraudes. Nuestros modelos de detección de fraudes automáticamente deciden si una persona puede significar riesgo de fraude o lavado de dinero. Si nuestros modelos de detección de fraude al procesar una transacción o aprobar un crédito detectan riesgo, es posible que el acceso a la persona se rehuse.
        </p>
        <p style="font-weight: bold;">III. Tus derechos</p>
        <p>
          Si solicitas un crédito y eres rechazado basado en una decisión automatizada, tienes derecho a acceder a tu información y rectificarla contactándonos como se establece en la sección Derechos ARCO.
        </p>
      </div>
    </div>

    <footPage></footPage>
  </div>
</template>

<script>
  import headNav from "@/components/headNav.vue";
  import footPage from "@/components/foot.vue";
  import "../components/scss/Aviso.scss";

  export default {
    name: "Aviso",
    components: { headNav, footPage },
    data() {
      return {};
    },
  };
</script>
