<template>
  <div class="Sobre">
    <headNav :activeNav="'sobre'"></headNav>
    <div class="p1"></div>

    <div style="margin:0 auto;">
      <div class="bigContent" style="margin-top:160px !important">

        <div class="content">
          <div class="title"><p>Perfil de la empresa</p></div>

          <div class="cont">
            <div class="item">
              <span><u>Credifast</u></span> es una plataforma que ofrece préstamos en línea rápidos, accesibles y seguros. Regístrate en segundos, aplica para un préstamo y recibe tu efectivo en minutos.Vemos el mundo diferente. Estamos motivados por una creencia fundamental en las personas, y trabajamos juntos para demostrar su potencial. Asumimos riesgos que otros no tomarían para poner más poder en las manos de nuestros clientes.
            </div>
            <img src="@/assets/img/2_img.png" alt="">
          </div>
        </div>
      </div>
      <div class="bigContent contTwo">
        <div class="titletwo"><img src="../assets/img/2_title.png" alt=""><p>Contáctanos</p></div>
        <p class="prelude"> Si necesitas ayuda, envíanos un correo o llamar a nuestro teléfono de atención al cliente.</p>
        <ul class="twoList">
          <li>
            <img src="@/assets/img/sj_icon.png" alt="">
            <div>
              <p class="t">Atención al Cliente：</p>
              <p class="c">+52 55 4440 6847</p>
            </div>
          </li>
          <li>
            <img src="@/assets/img/yx_icon.png" alt="">
            <div>
              <p class="t">Email：</p>
              <p class="c">Credifast.22.atc@gmail.com</p>
            </div>
          </li>
          <li>
            <img src="@/assets/img/time_icon.png" alt="">
            <div>
              <p class="t">Tiempo laboral：</p>
              <p class="c">De Lunes a Domingo: 9 am-6 pm</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <footPage></footPage>

  </div>
</template>

<script>
  import headNav from "@/components/headNav.vue";
  import footPage from "@/components/foot.vue";
  import "../components/scss/sobre.scss";

  export default {
    name: "Sobre",
    components: { headNav, footPage },
    data() {
      return {

      }
    }
  }
</script>

<style>

</style>
